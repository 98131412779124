@font-face {
font-family: '__circular_145c6d';
src: url(/_next/static/media/953024a52f881e3c.p.woff2) format('woff2');
font-display: block;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__circular_145c6d';
src: url(/_next/static/media/31828bda37d0a075.p.woff2) format('woff2');
font-display: block;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__circular_145c6d';
src: url(/_next/static/media/1cd2f9374051d0c3.p.woff2) format('woff2');
font-display: block;
font-weight: 600;
font-style: normal;
}@font-face {font-family: '__circular_Fallback_145c6d';src: local("Arial");ascent-override: 93.39%;descent-override: 23.96%;line-gap-override: 0.00%;size-adjust: 102.26%
}.__className_145c6d {font-family: '__circular_145c6d', '__circular_Fallback_145c6d'
}.__variable_145c6d {--font-circular: '__circular_145c6d', '__circular_Fallback_145c6d'
}

